
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinWizard from '@/mixins/mixinWizard';
import CommonInfobutton from "@/components/common/Infobutton.vue";
import CommonSelect from "@/components/common/Select.vue";

@Component({
  components: {
    CommonInfobutton,
    CommonSelect,
  }
})
export default class Main extends Mixins(mixinWizard) {
  get tmp_company() {
    return this.m.company;
  }
  set tmp_company(val) {
    this.m.setCompany({company: val});
  }
}
